<template>
    <b-card no-body class="p-1">
        <b-overlay
                :show="state.loading"
                rounded="sm"
        >
            <div class="demo-spacing-0 d-flex justify-content-between m-1" dir="rtl">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="isActive=true">
                    <feather-icon size="16" icon="FilterIcon"/>
                    فیلتر
                </b-button>
                <b-button v-if="$havePermission('CREATE_ROLES')" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="info"
                          @click="$router.push({name:'add-role'})">
                    <feather-icon size="16" icon="PlusIcon"/>
                    افزودن نقش
                </b-button>
            </div>
            <search-and-filter
                    :is-active.sync="isActive"
                    :options="columns"
                    @filter="getData(1, perPage)"
                    @reset="getData(1, perPage)"
            />
            <div class="relative-over-x">
                <b-table
                        ref="refUserListTable"
                        :items="items"
                        :small="true"
                        responsive
                        :fields="columns"
                        primary-key="id"
                        show-empty
                        empty-text="اطلاعاتی یافت نشد"
                        @sort-changed="sort($event)"
                        no-local-sort
                        class="text-nowrap"
                        style="min-height : 235px"
                >
                    <template #cell(persianName)="data">
                        <div class="text-nowrap d-inline-flex align-middle">
                            <feather-icon
                                    :icon="data.item.iconName"
                                    size="18"
                                    class="mr-50"
                                    :class="`text-${data.item.color}`"
                            />
                            <span class="align-text-top text-capitalize">{{ data.item.persianName }}</span>
                        </div>
                    </template>

                    <template #cell(englishName)="data">
                        <span dir="ltr">
                            {{ data.item.englishName }}
                        </span>
                    </template>

                    <template #cell(abilities)="data">
                        <feather-icon
                                :title="data.item.name"
                                icon="CheckSquareIcon"
                                class="cursor-pointer"
                                :badge="data.item.abilities_count"
                                size="20"
                                badge-classes="badge-info test-badge cursor-pointer"/>
                    </template>

                    <template #cell(created_at)="data">
                        <span dir="ltr">
                            {{ $G2J(data.item.created_at) }}
                        </span>
                    </template>

                    <template #cell(status)="data">
                        <b-badge
                                pill
                                :variant="'light-'+statusVariant(data.item.status)"
                                class="text-capitalize"
                        >
                            {{ statusLabel(data.item.status) }}
                        </b-badge>
                    </template>

                    <template #cell(action)="{item}">
                        <b-dropdown
                                id="dropdown-offset"
                                variant="link"
                                no-caret
                                offset="80px"
                                :right="true"
                        >
                            <template #button-content>
                                <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                />
                            </template>

                            <b-dropdown-item v-if="$havePermission('DETAILS_ROLES')"
                                             :to="{ name: 'show-role', params: { id: item.id } }">
                                <feather-icon icon="EditIcon"/>
                                <span class="align-middle ml-50">جزییات نقش</span>
                            </b-dropdown-item>

                            <b-dropdown-item v-if="$havePermission('UPDATE_ROLES')" @click="changeStatus(item)">
                                <feather-icon icon="RefreshCwIcon"/>
                                <span class="align-middle ml-50">تغییر وضعیت</span>
                            </b-dropdown-item>

                        </b-dropdown>
                    </template>
                </b-table>
            </div>

            <!-- pagination -->
            <div
                    class="demo-spacing-0 d-flex justify-content-between m-1"
                    dir="rtl"
            >
                <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        align="left"
                        @input="getData(currentPage,perPage)"
                >
                    <template #prev-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                </b-pagination>
                <div>
                    <label for="perpage">تعداد در صفحه</label>
                    <v-select
                            id="perpage"
                            v-model="perPage"
                            dir="rtl"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            style="min-width: 85px"
                            @input="getData(1,perPage)"
                    />
                </div>
            </div>
        </b-overlay>
    </b-card>
</template>

<script>
import {
    BPagination,
    BCard,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BBadge,
    VBPopover,
    BOverlay,
} from 'bootstrap-vue'
import SearchAndFilter from "@/layouts/components/SearchAndFilter";
import vSelect from 'vue-select';

export default {
    name: 'AdminAccounts',
    components: {
        SearchAndFilter,
        BPagination,
        BCard,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        BBadge,
        vSelect,
        BOverlay,
    },
    directives: {
        'b-popover': VBPopover,
    },
    data: () => ({
        test: 0,
        pageLength: 3,
        dir: false,
        searchTerm: '',
        currentPage: 1,
        perPage: 10,
        rows: 20,
        sortBy: '',
        isSortDirDesc: '',
        userData: [],
        isActive: false,
        perPageOptions: [5, 10, 20, 50, 100],
        privileges: [],
        items: [],
        columns: [
            {
                label: 'نقش',
                key: 'label',
                sortable: true,
                searchType: 'text',
            },
            {
                label: 'نام انگلیسی',
                key: 'name',
                sortable: true,
                searchType: 'text'
            },
            {
                label: 'دسترسی ها',
                key: 'abilities',
                sortable: false,
                // searchType: 'select',
                // selectOptions: []
            },
            {
                label: 'تاریخ ساخت نقش',
                key: 'created_at',
                sortable: true,
                searchType: 'date'
            },
            {
                label: 'وضعیت',
                key: 'status',
                sortable: true,
                searchType: 'select',
                selectOptions: [
                    {label: 'غیر فعال', value: 'DISABLED'},
                    {label: 'فعال', value: 'ACTIVATED'}
                ]
            },
            {
                label: 'مدیریت',
                key: 'action'
            },
        ],
    }),
    computed: {
        roleLabel() {
            const a = {
                MARKET: 'بازار ها',
                ORDERS: 'سفارش ها',
                TRADES: 'معاملات',
                USERS: 'کاربران سایت',
                ADMINS: 'مدیران',
                ROLES: 'نقش ها',
                DOLLARS: 'مدیریت تتر',
                WALLET: 'کیف پول ها',
                WITHDRAW: 'درخواست های برداشت',
                HISTORIES: 'تاریخچه',
                EXCHANGEINFO: 'اطلاعات بازار',
                FAQ: 'سوالات متداول',
                USER_MANUAL: 'راهنمای استفاده',
            }
            return e => a[e];
        },
        roleAccess() {
            const a = {
                UPDATE: 'اصلاح',
                LIST: 'لیست',
                DELETE: 'حذف',
                CREATE: 'ایجاد',
                DETAILS: 'جزییات',
            }
            return e => a[e];
        },
        statusVariant() {
            return e => (e === 'ACTIVATED') ? 'success' : 'danger'
        },
        statusLabel() {
            return e => (e === 'ACTIVATED') ? 'فعال' : 'غیر فعال'
        },
    },
    methods: {
        roleAccesses(e) {
            const a = [
                'MARKET',
                'ORDERS',
                'TRADES',
                'USERS',
                'ADMINS',
                'ROLES',
                'DOLLARS',
                'WALLET',
                'WITHDRAW',
                'HISTORIES',
                'EXCHANGEINFO',
                'FAQ',
                'USER_MANUAL',
            ]
            let string = ''
            for (let data of a) {
                string += this.roleLabel(data) + ' ( ' + this.$count(data, e) + ' ) - '
            }
            return string

        },

        changeStatus(e) {
            let item = e
            item.status = item.status === 'ACTIVATED' ? 'DISABLED' : 'ACTIVATED'
            this.$swal({
                title: 'آیا از تغییر وضعیت نقش مطمئن هستید؟',
                // text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'بله',
                cancelButtonText: 'خیر',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(async (result) => {
                if (result.value) {
                    console.warn('change active')
                    this.state.loading = true
                    const address = '/roles/' + e.id
                    await this.$axios.patch(address, item)
                    this.getData(this.currentPage, this.perPage)

                    this.$swal({
                        icon: 'success',
                        title: item.status === 'ACTIVATED' ? 'نقش فعال شد' : 'نقش غیر فعال شد',
                        confirmButtonText: 'تایید',
                        // text: 'Your file has been deleted.',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    })


                }
            })
        },

        sort(e) {

            let sort = this.$toSnakeCase(e.sortBy)
            // let sort = e.sortBy
            let sorting = e.sortDesc ? 'DESC' : 'ASC'

            this.$router.push({
                query: {
                    ...this.$route.query,
                    orderBy: sort,
                    sorting: sorting
                }
            })

            this.getData(1, this.perPage)

        },

        async getData(page, perPage) {
            const queryParams = {
                size: perPage,
                page: page,
                ...this.$route.query
            }
            this.state.loading = true
            const res = await this.$axios('/roles', {params: queryParams})
            this.state.loading = false
            this.items = res.data.data
            this.currentPage = res.data.meta.current_page
            this.rows = res.data.meta.total
        },
    },
    async created() {
        // this.state.loading = true
        // const res = await this.$axios('/roles/privileges')
        // this.privileges = res.data
        //
        // this.privileges = await this.privileges.map(x => {
        //     return {
        //         label: this.roleAccess(x.split('_')[0]) + ' ' + this.roleLabel(x.split('_')[1]),
        //         value: x
        //     }
        // })
        // console.warn('pre : =>>>> ', this.privileges)
        // this.state.loading = false
        await this.getData()
    },
}
</script>
<style lang="scss">
[v-cloak] {
  opacity: 0;
}

[dir="rtl"] .test-badge {
  font-size: 10px;
  top: -6px;
  left: -5px !important;
  min-width: 16px;
  min-height: 16px;
}
</style>
